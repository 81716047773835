import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    pageContainer: {
        marginBottom: 30,
        marginTop: 30
    },
    mainContainer: {
        backgroundColor: '#f6f7f6',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: 30
    },
    imagePagePart: {
        padding: 30,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#ccc'
    },
    loadingSubTitle: {
        fontStyle: 'italic',
        marginTop: 10,
        fontSize: 12
    }
});

export default styles;
