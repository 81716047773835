import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';

const AboutTheSale: React.FunctionComponent = () => {
    return <div className={css(styles.pageContainer)}>
        <div className={css(styles.mainContainer, styles.imagePagePart)}>
            <h2>Over de veiling</h2>

            <p>Het bieden op de veiling staat alleen open voor NFV-leden. We hebben gekozen voor een minimum prijs
                bij de veiling die is afgeleid van de verzekerde waarde. Op die manier kunnen leden voor een leuke
                prijs mogelijk een kast krijgen en is het niet zo dat we de kasten ‘weggeven’ en de andere leden het
                idee zouden kunnen krijgen dat de vereniging benadeeld wordt.</p>

            <p>De leden kunnen biedingen doen via de veiling website. Start biedingen: 1 augustus, einddatum
                veiling: 27 augustus 18.00u. De winnaars van de veiling krijgen persoonlijk bericht en de kasten
                kunnen dan op een klusdag opgehaald worden. De eerste gelegenheid daarvoor is zaterdag 29
                augustus.</p>

            <p>De spelregels voor de veiling zijn:</p>
            <ol>
                <li>Bieding geschiedt uitsluitend via de veiling website. Je voor en achternaam zullen op de veiling
                    website bij het
                    bod geplaatst worden, je gaat hiermee akkoord als je gebruik maakt van de veiling website. Ter
                    info: deze website is alleen zichtbaar voor ingelogde NFV-leden.
                </li>
                <li>Na sluiting van de veiling worden de resultaten op de website geplaatst.</li>
                <li>We hebben de kasten stuk voor stuk bekeken en naar beste kunnen een omschrijving gemaakt per
                    kast en gedetailleerde foto's gemaakt. Desondanks zullen er zeker gebreken zijn die niet
                    opgemerkt zijn door ons. Opmerkingen zoals in optisch goede staat houden rekening met de
                    leeftijd van de kast, betekent dus niet nieuwstaat. Houdt hier dus rekening mee met je bod,
                    bekijk de foto's dus goed, prijs is 'as is', zonder garantie! Biedingen zijn tevens niet
                    vrijblijvend maar verplichten je tot aankoop.
                </li>
                <li>Indien je een winnend bod hebt uitgebracht verkrijg je betaalinstructie. Betaling dient te
                    geschieden binnen 1 week en voor het ophalen. Eerste ophaalmogelijkheid is momenteel tijdens de
                    klusdag 29 augustus.
                </li>
            </ol>

            <p>Van sommige kasten ontbreekt de kopkastsleutel. Van deze kasten zijn dus geen foto's gemaakt van de
                binnenkant van de kopkast.</p>
        </div>
    </div>;
};


export default AboutTheSale;
