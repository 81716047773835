import {ActionType, createAction} from 'typesafe-actions';
import {PinballDTO} from '../../generated/swagger-api';

export const pinballModuleUpdatePinballGames = createAction(
    'PINBALL_MODULE_UPDATE_PINBALL_GAMES',
    (action) => (games: PinballDTO[]) => action({games})
);

export const pinballModuleReceivedPinballGameUpdate = createAction(
    'PINBALL_MODULE_RECEIVED_PINBALL_GAME_UPDATE',
    (action) => (pinball: PinballDTO) => action({pinball})
);

export type PinballModuleActionTypes = ActionType<typeof pinballModuleUpdatePinballGames> |
    ActionType<typeof pinballModuleReceivedPinballGameUpdate>;
