import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {welcomeSceneDidActivateScene, welcomeSceneNavigateToPinballGame} from '../../scenes/Welcome/actions';
import {push} from 'connected-react-router';
import {applicationModuleUpdateCurrentScene} from '../../modules/application/actions';
import {Scenes} from '../../scenes';
import {swaggerApiService} from '../../services';
import {DependencyEpic} from '../../store';

const changeSceneApplicationModuleOnWelcomeSceneDidActivate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneDidActivateScene)),
        map(() => applicationModuleUpdateCurrentScene(Scenes.WELCOME))
    );

const loadPinballsOnWelcomeSceneDidActive: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneDidActivateScene)),
        mergeMap(() => swaggerApiService.loadGamesList(state$.value.authenticationModule.sessionId!))
    );

const pushRouteOnNavigateToPinballGameRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneNavigateToPinballGame)),
        map(({payload: {slug}}) => push(`/flipperkast/${slug}`))
    );

const welcomeSceneEpics: Epic = combineEpics(
    changeSceneApplicationModuleOnWelcomeSceneDidActivate,
    pushRouteOnNavigateToPinballGameRequested,
    loadPinballsOnWelcomeSceneDidActive
);

export default welcomeSceneEpics;
