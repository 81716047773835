import {Reducer} from 'redux';
import {
    PinballSceneActionTypes,
    pinballSceneClearCurrentBidValue,
    pinballSceneDidDeactivateScene,
    pinballSceneReceivedPinballGame,
    pinballSceneUpdateManualBidValue,
    pinballSceneUpdatePinballGameData,
    pinballSceneValidateInputValue
} from './actions';
import {getType} from 'typesafe-actions';
import {PinballDTO} from '../../generated/swagger-api';
import pinball from '../../models/Pinball';

export type PINBALL_LOADING_STATE = 'INITIAL' | 'NOT_FOUND' | 'FOUND';

export interface PinballSceneReducerState<T = PINBALL_LOADING_STATE> {
    loadingState: T;
    pinballGame: T extends 'FOUND' ? PinballDTO : null;
    manualBidValue: string;
    validatedManualBidValue: boolean | null;
}

const initialState: PinballSceneReducerState = {
    loadingState: 'INITIAL',
    pinballGame: null,
    manualBidValue: '',
    validatedManualBidValue: null
};

const pinballSceneReducer: Reducer<PinballSceneReducerState, PinballSceneActionTypes> =
    (state: PinballSceneReducerState = initialState, action: PinballSceneActionTypes): PinballSceneReducerState => {
        switch (action.type) {
            case getType(pinballSceneReceivedPinballGame):
                return {
                    ...state,
                    loadingState: action.payload.loadingState,
                    pinballGame: action.payload.pinballGame
                };
            case getType(pinballSceneUpdatePinballGameData):
                return {
                    ...state,
                    pinballGame: action.payload.pinballGame
                };
            case getType(pinballSceneDidDeactivateScene):
                return {
                    ...state,
                    loadingState: 'INITIAL',
                    pinballGame: null,
                    manualBidValue: '',
                    validatedManualBidValue: null
                };
            case getType(pinballSceneUpdateManualBidValue):
                return {
                    ...state,
                    manualBidValue: action.payload.value
                };
            case getType(pinballSceneValidateInputValue):
                let valid: boolean | null = false;

                if (state.manualBidValue.length === 0) {
                    valid = null;
                } else if (!isNaN(Number(state.manualBidValue))) {
                    if (Number(state.manualBidValue) >= pinball.nextBidValue(state.pinballGame!)) {
                        valid = true;
                    }
                }

                return {
                    ...state,
                    validatedManualBidValue: valid
                };
            case getType(pinballSceneClearCurrentBidValue):
                return {
                    ...state,
                    validatedManualBidValue: null,
                    manualBidValue: ''
                };
            default:
                return {
                    ...state
                };
        }
    };

export default pinballSceneReducer;
