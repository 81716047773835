import Utils from './Utils';
import SwaggerApiService from './SwaggerApiService';
import {SocketService} from './SocketService';
import {NotificationService} from './NotificationService';
import FirebaseService from './FirebaseService';

export const firebaseService: FirebaseService = new FirebaseService();
export const notificationService: NotificationService = new NotificationService();
export const socketService: SocketService = new SocketService();
export const swaggerApiService: SwaggerApiService = new SwaggerApiService();
export const utilsService: Utils = new Utils();
