import {ActionType, createAction} from 'typesafe-actions';
import {Scenes} from '../../scenes';

export const applicationModuleApplicationColdBoot = createAction(
    'APPLICATION_MODULE_APPLICATION_COLD_BOOT',
    (action) => () => action({})
);

export const applicationModuleBootWithSessionUrl = createAction(
    'APPLICATION_MODULE_BOOT_WITH_SESSION_URL',
    (action) => (sessionId: string) => action({sessionId})
);

export const applicationModuleApplicationBootCompleted = createAction(
    'APPLICATION_MODULE_APPLICATION_BOOT_COMPLETED',
    (action) => () => action({})
);

export const applicationModuleApplicationBootFirebase = createAction(
    'APPLICATION_MODULE_APPLICATION_BOOT_FIREBASE',
    (action) => () => action({})
);

export const applicationModuleUpdateCurrentScene = createAction(
    'APPLICATION_MODULE_UPDATE_CURRENT_SCENE',
    (action) => (scene: Scenes) => action({scene})
);

export const applicationModuleFinishedLoadingFirebase = createAction(
    'APPLICATION_MODULE_FINISHED_LOADING_FIREBASE',
    (action) => () => action({})
);

export const applicationModuleErrorLoadingFirebase = createAction(
    'APPLICATION_MODULE_ERROR_LOADING_FIREBASE',
    (action) => (err: Error) => action({err})
);

export type ApplicationModuleActionTypes = ActionType<typeof applicationModuleApplicationColdBoot> |
    ActionType<typeof applicationModuleBootWithSessionUrl> |
    ActionType<typeof applicationModuleApplicationBootCompleted> |
    ActionType<typeof applicationModuleUpdateCurrentScene> |
    ActionType<typeof applicationModuleApplicationBootFirebase> |
    ActionType<typeof applicationModuleFinishedLoadingFirebase> |
    ActionType<typeof applicationModuleErrorLoadingFirebase>;
