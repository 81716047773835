import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {browserHistory, store} from './store';
import {ToastContainer} from 'react-toastify';
import './assets/css/main.css';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/nl.js';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App browserHistory={browserHistory}/>
            <ToastContainer/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
