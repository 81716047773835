import {ActionType, createAction} from 'typesafe-actions';
import {TourSceneNotificationState, TourSceneStep} from './reducer';

export const tourSceneDidActivateScene = createAction(
    'TOUR_SCENE_DID_ACTIVATE',
    (action) => () => action({})
);

export const tourSceneDidDeactivateScene = createAction(
    'TOUR_SCENE_DID_DEACTIVATE',
    (action) => () => action({})
);

export const tourSceneGoToNextStep = createAction(
    'TOUR_SCENE_GO_TO_NEXT_STEP',
    (action) => () => action({})
);

export const tourSceneSetStep = createAction(
    'TOUR_SCENE_SET_STEP',
    (action) => (nextStep: TourSceneStep) => action({nextStep})
);

export const tourSceneRequestNotificationPermission = createAction(
    'TOUR_SCENE_REQUEST_NOTIFICATION_PERMISSION',
    (action) => () => action({})
);

export const tourSceneUpdateNotificationPermissionState = createAction(
    'TOUR_SCENE_UPDATE_NOTIFICATION_PERMISSION_STATE',
    (action) => (notificationState: TourSceneNotificationState) => action({notificationState})
);

export const tourSceneFinishedSettingUpNotifications = createAction(
    'TOUR_SCENE_FINISHED_SETTING_UP_NOTIFICATIONS',
    (action) => () => action({})
);

export const tourSceneFinishTour = createAction(
    'TOUR_SCENE_FINISH_TOUR',
    (action) => () => action({})
);

export type TourSceneActionTypes = ActionType<typeof tourSceneDidActivateScene> |
    ActionType<typeof tourSceneDidDeactivateScene> |
    ActionType<typeof tourSceneGoToNextStep> |
    ActionType<typeof tourSceneSetStep> |
    ActionType<typeof tourSceneRequestNotificationPermission> |
    ActionType<typeof tourSceneUpdateNotificationPermissionState> |
    ActionType<typeof tourSceneFinishedSettingUpNotifications> |
    ActionType<typeof tourSceneFinishTour>;
