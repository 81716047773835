import {combineEpics, Epic} from 'redux-observable';
import applicationEpics from './epics/application';
import authenticationModuleEpics from './epics/authenticationModule';
import {pinballModuleEpics} from './epics/pinballModule';
import pinballSceneEpic from './epics/pinballScene';
import welcomeSceneEpics from './epics/welcomeScene';
import appComponent from './epics/appComponent';
import myBidsSceneEpic from './epics/myBidsSceneEpics';
import tourSceneEpics from './epics/tourSceneEpics';
import firebaseService from './epics/firebaseService';

const rootEpic: Epic = combineEpics(
    appComponent,
    applicationEpics,
    authenticationModuleEpics,
    firebaseService,
    myBidsSceneEpic,
    pinballModuleEpics,
    pinballSceneEpic,
    tourSceneEpics,
    welcomeSceneEpics
);

export default rootEpic;
