import {ActionType, createAction} from 'typesafe-actions';

export const welcomeSceneDidActivateScene = createAction(
    'WELCOME_SCENE_DID_ACTIVATE',
    (action) => () => action({})
);

export const welcomeSceneDidDeactivateScene = createAction(
    'WELCOME_SCENE_DID_DEACTIVATE',
    (action) => () => action({})
);

export const welcomeSceneNavigateToPinballGame = createAction(
    'WELCOME_SCENE_NAVIGATE_TO_PINBALL_GAME',
    (action) => (slug: string) => action({slug})
);

export type WelcomeSceneActionTypes = ActionType<typeof welcomeSceneDidActivateScene> |
    ActionType<typeof welcomeSceneDidDeactivateScene> |
    ActionType<typeof welcomeSceneNavigateToPinballGame>;
