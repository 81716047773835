import {combineEpics, Epic} from 'redux-observable';
import {filter, flatMap, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {swaggerApiServicePinballControllerListReceivedData} from '../../services/SwaggerApiService/actions/pinballControllerList';
import {pinballModuleReceivedPinballGameUpdate, pinballModuleUpdatePinballGames} from '../../modules/pinball/actions';
import {applicationModuleApplicationBootCompleted} from '../../modules/application/actions';
import {socketService} from '../../services';

const updatePinballModuleOnSwaggerApiClientReceivedPinballList: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServicePinballControllerListReceivedData)),
        map(({payload: {games}}) => pinballModuleUpdatePinballGames(games))
    );

const registerForPinballUpdatesAfterApplicationBootCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        flatMap(() => socketService.onPinballUpdate()
            .pipe(
                map((pinballGame) => pinballModuleReceivedPinballGameUpdate(pinballGame))
            ))
    );

export const pinballModuleEpics: Epic = combineEpics(
    updatePinballModuleOnSwaggerApiClientReceivedPinballList,
    registerForPinballUpdatesAfterApplicationBootCompleted
);
