import {applyMiddleware, compose, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createEpicMiddleware, Epic} from 'redux-observable';
import rootReducer, {RootState} from './reducer';
import rootEpic from './epic';
import {Action} from 'typesafe-actions';
import {applicationModuleApplicationColdBoot} from './modules/application/actions';
import {BASE} from './routes';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {ReplaySubject} from 'rxjs';
import firebase from 'firebase';
import {firebaseService} from './services';

export interface EpicMiddlewareDependencies {
    firebase: ReplaySubject<firebase.app.App>;
}

const reduxDevToolsEnabled: boolean = process.env.REACT_APP_DEV_TOOLS_CONFIG_ENABLED === '1';
const composeEnhancers = reduxDevToolsEnabled ?
    composeWithDevTools({
        name: 'NFV - Kasten veiling'
    })
    : compose;

export const browserHistory = createBrowserHistory({basename: BASE});
const epicMiddleware = createEpicMiddleware<Action, Action, void, EpicMiddlewareDependencies>({
    dependencies: {
        firebase: firebaseService.getReplaySubject()
    }
});
export type DependencyEpic = Epic<any, any, RootState, EpicMiddlewareDependencies>;
export const store = createStore(
    rootReducer(browserHistory),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(browserHistory),
            epicMiddleware
        )
    )
);

epicMiddleware.run(rootEpic);
store.dispatch(applicationModuleApplicationColdBoot());
