import {ActionType, createAction} from 'typesafe-actions';
import {UserBidDTO} from '../../generated/swagger-api';

export const myBidsSceneDidActivateScene = createAction(
    'MY_BIDS_SCENE_DID_ACTIVATE',
    (action) => () => action({})
);

export const myBidsSceneDidDeactivateScene = createAction(
    'MY_BIDS_SCENE_DID_DEACTIVATE',
    (action) => () => action({})
);

export const myBidsSceneLoadCurrentUserBids = createAction(
    'MY_BIDS_SCENE_LOAD_CURRENT_USER_BIDS',
    (action) => () => action({})
);

export const myBidsSceneStoreUserBidsData = createAction(
    'MY_BIDS_SCENE_STORE_USER_BIDS_DATA',
    (action) => (userBids: UserBidDTO[]) => action({userBids})
);

export const myBidsSceneVisitPinballScene = createAction(
    'MY_BIDS_SCENE_VISIT_PINBALL_SCENE',
    (action) => (slug: string) => action({slug})
);

export type MyBidsSceneActionTypes = ActionType<typeof myBidsSceneDidActivateScene> |
    ActionType<typeof myBidsSceneDidDeactivateScene> |
    ActionType<typeof myBidsSceneLoadCurrentUserBids> |
    ActionType<typeof myBidsSceneStoreUserBidsData> |
    ActionType<typeof myBidsSceneVisitPinballScene>;
