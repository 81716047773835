import io from 'socket.io-client';
import {fromEvent, Observable} from 'rxjs';
import {PinballDTO} from '../../generated/swagger-api';
import {map} from 'rxjs/operators';

export class SocketService {

    private socket: SocketIOClient.Socket | null;

    constructor() {
        this.socket = null;
    }

    init(authorization: string) {
        this.socket = io(`${process.env.REACT_APP_CONFIG_API_BASE_URL}`, {
            query: {authorization},
            path: '/api/socket/pinball'
        });
    }

    onPinballUpdate(): Observable<PinballDTO> {
        return fromEvent<string>(this.socket!, 'pinballUpdate')
            .pipe(
                map<string, PinballDTO>((payload) => JSON.parse(payload))
            );
    }

    onAuthError(): Observable<string> {
        return fromEvent<string>(this.socket!, 'auth-error');
    }
}
