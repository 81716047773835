import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    pageContainer: {
        minHeight: '100%',
        minWidth: '100%',
        backgroundSize: 'cover',
        padding: 70
    },
    pageContent: {
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    }
});

export default styles;
