import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    pageContainer: {
        marginBottom: 20,
        marginTop: 20
    },
    mainContainer: {
        backgroundColor: '#f6f7f6',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: 30
    },
    sidebarPadding: {
        marginBottom: 30
    },
    titlePagePart: {
        padding: 20,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#ccc'
    },
    titleDescription: {
        fontSize: '75%'
    },
    imagePagePart: {
        padding: 30,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#ccc'
    },
    descriptionPagePart: {
        padding: 20
    },
    descriptionText: {
        whiteSpace: 'pre-wrap'
    },
    sidebarHeader: {
        backgroundColor: '#3F87CF',
        color: 'white',
        padding: 10
    },
    sidebarContentText: {
        marginTop: 0,
        marginBottom: 0
    },
    sidebarContent: {
        padding: 10,
        fontSize: '75%'
    },
    upBidText: {
        marginTop: 10,
        fontWeight: 'bold'
    },
    buttonGroupBidUp: {
        textAlign: 'center'
    },
    shakeInput: {
        animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        transform: 'translate3d(0, 0, 0)',
        backfaceVisibility: 'hidden'
    }
});

export default styles;
