import React, {useContext} from 'react';
import {Col, Container, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {AuthenticationContext} from '../../../contexts/authentication';
import {Props} from './Props';
import {Scenes} from '../../../scenes';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import pinballFlipper from '../../../assets/images/pinball-flipper.png';

const MainLayout: React.FunctionComponent<Props> = (
    {
        children,
        currentScene,
        clickedOnHomeLink,
        clickedOnAdminLink,
        clickedOnSignOutLink,
        clickedOnMyBidsLink,
        clickedOnAboutTheSale,
        clickedOnHowToSale
    }) => {
    const authenticationContext = useContext(AuthenticationContext);

    const renderUserFullName = (): string => {
        if (!authenticationContext.userProfile) {
            return '';
        }

        return `${authenticationContext.userProfile.firstName} ${authenticationContext.userProfile.lastName}`;
    };

    const renderIsAdmin = (): boolean => {
        return false;
    };

    const handleLogoutClick = () => {
        if (window.confirm('Weet je zeker dat je wilt uitloggen?')) {
            clickedOnSignOutLink();
        }
    };

    const year = (new Date()).getFullYear();

    return <React.Fragment>
        <div
            style={{backgroundImage: `url("${pinballFlipper}")`}}
            className={css(styles.pageContainer)}
        >
            <Navbar bg={'dark'} variant={'dark'} expand={'lg'}>
                <Navbar.Brand onClick={clickedOnHomeLink}>NFV Kasten Veiling</Navbar.Brand>
                <Navbar.Toggle aria-controls={'main-navigation'}/>
                <Navbar.Collapse id={'main-navigation'}>
                    <Nav className="mr-auto">
                        <Nav.Link
                            onClick={clickedOnHomeLink}
                            active={currentScene === Scenes.WELCOME}
                        >
                            Overzicht
                        </Nav.Link>
                        <Nav.Link
                            onClick={clickedOnMyBidsLink}
                            active={currentScene === Scenes.MY_BIDS}
                        >
                            Mijn biedingen
                        </Nav.Link>
                        <Nav.Link
                            href={'https://drive.google.com/drive/folders/1UdewXUpLMLex9RkoFBJ8ORH3T7hW1k3n?usp=drive_open'}
                            title={'Door hier te klikken zie je alle gemaakte foto\'s (600 st) in een Google foto album'}
                            target={'_blank'}
                        >
                            Alle foto's
                        </Nav.Link>
                    </Nav>

                    {authenticationContext.authenticated && <>
                        <Nav>
                            <NavDropdown id={''} title={`Welkom terug, ${renderUserFullName()}!`}>
                                {renderIsAdmin() && <>
                                    <NavDropdown.Item
                                        onClick={clickedOnAdminLink}
                                        active={currentScene === Scenes.PINBALL_ADMIN}
                                    >
                                        Beheer
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                </>}
                                <NavDropdown.Item onClick={handleLogoutClick}>Uitloggen</NavDropdown.Item>
                            </NavDropdown>
                            <Navbar.Text>
                                {authenticationContext.authenticated && <React.Fragment>

                                </React.Fragment>}
                            </Navbar.Text>
                        </Nav>
                    </>}
                </Navbar.Collapse>
            </Navbar>
            <Container fluid={'lg'}>
                {children}

                <hr/>

                <footer className={css(styles.footerContainer)}>
                    <Row>
                        <Col xs={12} md={6}>
                            &copy; {year} Nederlandse Flipper Vereniging
                        </Col>
                        <Col xs={12} md={6}>
                            <div className={'text-right'}>
                                <a href={'/over-de-veiling'} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                    e.preventDefault();
                                    clickedOnAboutTheSale();
                                }}>
                                    Over de NFV kasten veiling
                                </a>&nbsp;&bull;&nbsp;
                                <a href={'/hoe-werkt-de-kasten-veiling'}
                                   onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                       e.preventDefault();
                                       clickedOnHowToSale();
                                   }}>
                                    Hoe werkt de kasten veiling?
                                </a>
                            </div>
                        </Col>
                    </Row>
                </footer>
            </Container>
        </div>
    </React.Fragment>;
};

export default MainLayout;
