import {createAction} from 'typesafe-actions';

export const firebaseServiceMessagingToken = createAction(
    'FIREBASE_SERVICE_MESSAGING_TOKEN',
    (action) => (token: string) => action({token})
);

export const firebaseServiceMessagingTokenFailed = createAction(
    'FIREBASE_SERVICE_MESSAGING_TOKEN_FAILED',
    (action) => () => action({})
);
