import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    pageHeader: {
        backgroundColor: '#3F87CF',
        backgroundSize: '150px',
        backgroundPositionX: '98%',
        backgroundPositionY: '90%',
        backgroundRepeat: 'no-repeat',
        padding: 20,
        color: 'white'
    },
    pageContent: {
        padding: 20
    },
    notificationStatusContainer: {
        position: 'relative'
    },
    notificationStatusFlag: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        color: 'white'
    }
});

export default styles;
