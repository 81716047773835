import React, {FormEvent, useEffect, useRef} from 'react';
import {DispatchProps, Props, StateProps} from './Props';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {
    pinballSceneAddBidToGame,
    pinballSceneDidActivateScene,
    pinballSceneDidDeactivateScene,
    pinballSceneMountedWithPinballSlug,
    pinballSceneStopListeningForGameChanges,
    pinballSceneUpdateManualBidValue,
    pinballSceneValidateInputValue
} from './actions';
import {RootState} from '../../reducer';
import {Button, ButtonGroup, Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import PinballStatus from '../../components/PinballStatus';
import pinball from '../../models/Pinball';
import ImageGallery from 'react-image-gallery';
import CurrencyEuro from '../../components/CurrencyEuro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGavel} from '@fortawesome/free-solid-svg-icons';
import pinballGif from '../../assets/images/pinball.gif';
import {PinballDTO, PinballDTOStateEnum} from '../../generated/swagger-api';
import debounce from 'lodash.debounce';

const PinballScene: React.FunctionComponent<Props> = (
    {
        match,
        onMountedWithPinballSlug,
        loadingState,
        pinballGame,
        onStopListeningPinballGameChanges,
        onActivatedScene,
        onDeactivatedScene,
        onAddBidToGame,
        onUpdateManualBidValue,
        manualBidValue,
        validationManualBidValue,
        onValidateInputValue
    }) => {
    useEffect(
        () => {
            onMountedWithPinballSlug(match.params.id);

            return function cleanup() {
                onStopListeningPinballGameChanges();
            };
        },
        [match.params.id, onMountedWithPinballSlug, onStopListeningPinballGameChanges]
    );
    useEffect(
        () => {
            onActivatedScene();

            return function cleanup() {
                onDeactivatedScene();
            };
        },
        [onActivatedScene, onDeactivatedScene]
    );

    const delayedValidationTrigger = useRef(debounce(() => onValidateInputValue(), 400, {
        leading: false,
        trailing: true
    })).current;

    const addAmountToGame = (value: number) =>
        addBidToGame(pinballGame!.id, pinballGame!.name, (pinballGame!.currentHighestBid || pinballGame!.startingPrice) + value);

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>, pinballSubmit: PinballDTO) => {
        e.preventDefault();

        if (!validationManualBidValue) {
            return;
        }

        if (isNaN(Number(manualBidValue))) {
            return;
        }

        addBidToGame(pinballSubmit.id, pinballSubmit.name, Number(manualBidValue));
    };

    const addBidToGame = (pinballId: string, pinballName: string, value: number) => {
        const formatted = new Intl.NumberFormat('nl', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

        if (window.confirm(`Weet je zeker dat je bod wilt plaatsen op ${pinballName} van ${formatted}?`)) {
            onAddBidToGame(pinballId, value);
        }
    };

    return <React.Fragment>
        {loadingState === 'INITIAL' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer, styles.imagePagePart)}>
                        <h2>Bezig met zoeken...</h2>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <img src={pinballGif} className={'img-fluid text-right'} alt={'Helaas niet gevonden...'}/>
                </Col>
            </Row>
        </div>}
        {loadingState === 'NOT_FOUND' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer, styles.imagePagePart)}>
                        <h2>We kunnen de flipperkast niet vinden :(</h2>

                        <p>We zijn even aan het spelen geweest om je kast te vinden, maar helaas hebben we het hem niet
                            kunnen vinden. We vermoeden dat hij wel ergens verstopt staat, dus klik boven aan op
                            'Overzicht'
                            om naar alle kasten te gaan die in de verkoop zijn.</p>

                        <p>&nbsp;</p>

                        <ButtonGroup>
                            <Button variant={'outline-primary'}>Ga naar de veilingkasten</Button>
                            <Button variant={'outline-primary'}>Ga naar jouw biedingen</Button>
                        </ButtonGroup>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <img src={pinballGif} className={'img-fluid text-right'} alt={'Helaas niet gevonden...'}/>
                </Col>
            </Row>
        </div>}

        {loadingState === 'FOUND' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer)}>
                        <div className={css(styles.titlePagePart)}>
                            <h3>{pinballGame?.name}</h3>

                            <div>
                                <PinballStatus pinballGame={pinballGame!} smallFontSize={false}/>&nbsp;&nbsp;

                                <span className={css(styles.titleDescription)}>
                                    <strong>Begin prijs:</strong> <CurrencyEuro amount={pinballGame!.startingPrice}/>
                                    {pinballGame!.currentHighestBid &&
                                    <> | <strong>Huidig bod:</strong> <CurrencyEuro
                                        amount={pinballGame!.currentHighestBid}/></>}
                                </span>
                            </div>
                        </div>

                        <div className={css(styles.imagePagePart)}>
                            <ImageGallery
                                items={pinball.convertImagesForSlider(pinballGame?.urlsImages || [])}
                                infinite={true}
                                lazyLoad={true}
                                autoPlay={true}
                                showThumbnails={true}
                                slideDuration={1000}
                                slideInterval={5000}
                                thumbnailPosition={'right'}
                            />
                        </div>

                        <div className={css(styles.descriptionPagePart)}>
                            <h5>Omschrijving</h5>

                            <p className={css(styles.descriptionText)}>
                                {pinball.convertDescription('decode', pinballGame!.description)}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className={css(styles.mainContainer, styles.sidebarPadding)}>
                        <div className={css(styles.sidebarHeader)}>
                            <h6 className={css(styles.sidebarContentText)}>
                                Biedingen
                            </h6>
                        </div>

                        <div className={css(styles.sidebarContent)}>
                            <table className={'table table-borderless table-xs'}>
                                <tbody>
                                {pinballGame!.bids.map((pinballGameBid) => {
                                    return <tr key={pinballGameBid.id}>
                                        <td>{pinballGameBid.bidderName}</td>
                                        <td className={'text-right'}><strong><CurrencyEuro
                                            amount={pinballGameBid.amount}/></strong></td>
                                    </tr>;
                                })}
                                {!pinballGame?.bids.length && <>
                                    <tr>
                                        <td colSpan={3}>
                                            Geen biedingen geplaatst
                                        </td>
                                    </tr>
                                </>}
                                </tbody>
                            </table>

                            <hr/>

                            {pinballGame?.state === PinballDTOStateEnum.Started && <form
                                onSubmit={(e) => handleFormSubmit(e, pinballGame)}
                            >
                                <InputGroup>
                                    <FormControl
                                        className={validationManualBidValue === false ? css(styles.shakeInput) : undefined}
                                        type={'number'}
                                        placeholder={`Bieden vanaf: € ${pinball.nextBidValue(pinballGame!)}`}
                                        onChange={(e) => {
                                            delayedValidationTrigger();
                                            onUpdateManualBidValue(e.target.value);
                                        }}
                                        onBlur={() => onValidateInputValue()}
                                        value={manualBidValue}
                                        isInvalid={validationManualBidValue === false ? true : undefined}
                                        isValid={validationManualBidValue ? true : undefined}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="primary"
                                            type={'submit'}
                                            disabled={validationManualBidValue === false || validationManualBidValue === null}
                                        >
                                            <FontAwesomeIcon icon={faGavel}/> Bieden
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                                <div className={css(styles.upBidText)}>Huidige bod verhogen met:</div>
                                <div className={css(styles.buttonGroupBidUp)}>
                                    <ButtonGroup size={'sm'}>
                                        {pinballGame?.bidValues.map((value) =>
                                            <Button key={`bid-value-${value}`} onClick={() => addAmountToGame(value)}
                                                    variant={'outline-info'}>€ {value}</Button>)}
                                    </ButtonGroup>
                                </div>
                            </form>}
                            {pinballGame?.state !== PinballDTOStateEnum.Started && <>
                                <p><i>Bieden is op dit moment niet mogelijk</i></p>
                            </>}
                        </div>
                    </div>
                    <div className={css(styles.mainContainer, styles.sidebarPadding)}>
                        <div className={css(styles.sidebarHeader)}>
                            <h6 className={css(styles.sidebarContentText)}>Kenmerken</h6>
                        </div>
                        <div className={css(styles.sidebarContent)}>
                            <table className={'table table-borderless table-sm'}>
                                <tbody>
                                {pinballGame?.attributes.map(({key, value}) => <tr key={`${key}-${value}`}>
                                    <th>{key}</th>
                                    <td>:</td>
                                    <td>{value}</td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>}
    </React.Fragment>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    loadingState: state.pinballScene.loadingState,
    pinballGame: state.pinballScene.pinballGame,
    manualBidValue: state.pinballScene.manualBidValue,
    validationManualBidValue: state.pinballScene.validatedManualBidValue
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onActivatedScene: () => dispatch(pinballSceneDidActivateScene()),
    onDeactivatedScene: () => dispatch(pinballSceneDidDeactivateScene()),
    onAddBidToGame: (pinballId, value) => dispatch(pinballSceneAddBidToGame(pinballId, value)),
    onMountedWithPinballSlug: (slug: string) => dispatch(pinballSceneMountedWithPinballSlug(slug)),
    onStopListeningPinballGameChanges: () => dispatch(pinballSceneStopListeningForGameChanges()),
    onUpdateManualBidValue: (value) => dispatch(pinballSceneUpdateManualBidValue(value)),
    onValidateInputValue: () => dispatch(pinballSceneValidateInputValue())
});

export default connect(mapStateToProps, mapDispatchToProps)(PinballScene);
