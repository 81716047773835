import React, {useEffect} from 'react';
import {DispatchProps, Props, StateProps} from './Props';
import {connect} from 'react-redux';
import {
    MyBidsSceneActionTypes,
    myBidsSceneDidActivateScene,
    myBidsSceneDidDeactivateScene,
    myBidsSceneVisitPinballScene
} from './actions';
import {Dispatch} from 'redux';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {RootState} from '../../reducer';
import {Button, Col, Row} from 'react-bootstrap';
import pinballGif from '../../assets/images/pinball.gif';
import CurrencyEuro from '../../components/CurrencyEuro';

const MyBidsScene: React.FunctionComponent<Props> = (
    {
        onActivatedScene,
        onDeactivatedScene,
        onVisitPinballScene,
        bids
    }) => {
    useEffect(
        () => {
            onActivatedScene();

            return function cleanup() {
                onDeactivatedScene();
            };
        },
        [onActivatedScene, onDeactivatedScene]
    );

    return <>
        <div className={css(styles.pageContainer)}>
            {!bids && <>
                <Row>
                    <Col xs={12} md={8}>
                        <div className={css(styles.mainContainer, styles.imagePagePart)}>
                            <h2>Bezig met ophalen van je biedingen...</h2>

                            <p>
                                <span className={css(styles.loadingSubTitle)}>
                                    Misschien spelen we ondertussen stiekem wel een snel potje&nbsp;
                                </span>
                                😁
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={pinballGif} className={'img-fluid text-right'} alt={'Helaas niet gevonden...'}/>
                    </Col>
                </Row>
            </>}
            {bids && <>
                <div className={css(styles.mainContainer, styles.imagePagePart)}>
                    <h2>Mijn biedingen</h2>

                    <p>Op deze pagina zie je een overzicht met al je biedingen, de biedingen in rood zijn flipperkasten
                        waarop je bent overboden door iemand anders.</p>

                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>Flipperkast</th>
                            <th>Bedrag</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {bids.map((bid) => <React.Fragment key={bid.id}>
                            <tr>
                                <td>{bid.pinballName}</td>
                                <td className={bid.highestBid ? 'text-success' : 'text-danger'}><CurrencyEuro
                                    amount={bid.amount}/></td>
                                <td className={'text-right'}>
                                    <Button
                                        variant={'outline-primary'}
                                        onClick={() => onVisitPinballScene(bid.pinballSlug)}
                                    >
                                        Ga naar flipperkast
                                    </Button>
                                </td>
                            </tr>
                        </React.Fragment>)}
                        </tbody>
                    </table>
                </div>
            </>}
        </div>
    </>;
};

const mapDispatchToProps = (dispatch: Dispatch<MyBidsSceneActionTypes>): DispatchProps => ({
    onActivatedScene: () => dispatch(myBidsSceneDidActivateScene()),
    onDeactivatedScene: () => dispatch(myBidsSceneDidDeactivateScene()),
    onVisitPinballScene: (slug) => dispatch(myBidsSceneVisitPinballScene(slug))
});

const mapStateToProps = (state: RootState): StateProps => ({
    bids: state.myBids.bids
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBidsScene);
