import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    filterContainer: {
        backgroundColor: '#f6f7f6',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: 30,
        padding: 20
    },
    filterText: {
        fontSize: '80%'
    },
    gamesContainer: {
        marginTop: 50
    },
    imageContainer: {
        '::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%'
        },
        'position': 'relative'
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'fill'
    }
});

export default styles;
