import React from 'react';
import {Props} from './Props';
import {Badge} from 'react-bootstrap';
import pinball from '../../models/Pinball';
import {css} from 'aphrodite';
import styles from './styles';

const PinballStatus: React.FunctionComponent<Props> = ({pinballGame, smallFontSize}) => {
    const {badgeType, text} = pinball.statusBadge(pinballGame);

    const className = smallFontSize ? css(styles.text) : undefined;

    return <>
        <Badge variant={badgeType} className={className}>{text}</Badge>
    </>;
};

export default PinballStatus;
