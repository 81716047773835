import {ActionType, createAction} from 'typesafe-actions';
import {PINBALL_LOADING_STATE} from './reducer';
import {PinballDTO} from '../../generated/swagger-api';

export const pinballSceneDidActivateScene = createAction(
    'PINBALL_SCENE_DID_ACTIVATE',
    (action) => () => action({})
);

export const pinballSceneDidDeactivateScene = createAction(
    'PINBALL_SCENE_DID_DEACTIVATE',
    (action) => () => action({})
);

export const pinballSceneMountedWithPinballSlug = createAction(
    'PINBALL_SCENE_MOUNTED_WITH_PINBALL_SLUG',
    (action) => (slug: string) => action({slug})
);

export const pinballSceneStartListeningForGameChanges = createAction(
    'PINBALL_SCENE_START_LISTENING_FOR_GAME_CHANGES',
    (action) => (uid: string) => action({uid})
);

export const pinballSceneStopListeningForGameChanges = createAction(
    'PINBALL_SCENE_STOP_LISTENING_FOR_GAME_CHANGES',
    (action) => () => action({})
);

export const pinballSceneReceivedPinballGame = createAction(
    'PINBALL_SCENE_RECEIVED_PINBALL_GAME',
    (action) =>
        <T extends PINBALL_LOADING_STATE>(loadingState: T, pinballGame: T extends 'FOUND' ? PinballDTO : null) =>
            action({loadingState, pinballGame})
);

export const pinballSceneUpdatePinballGameData = createAction(
    'PINBALL_SCENE_UPDATE_PINBALL_GAME_DATA',
    (action) => (pinballGame: PinballDTO) => action({pinballGame})
);

export const pinballSceneAddBidToGame = createAction(
    'PINBALL_SCENE_ADD_BIT_TO_GAME',
    (action) => (pinballId: string, value: number) => action({pinballId, value})
);

export const pinballSceneUpdateManualBidValue = createAction(
    'PINBALL_SCENE_UPDATE_MANUAL_BID_VALUE',
    (action) => (value: string) => action({value})
);

export const pinballSceneValidateInputValue = createAction(
    'PINBALL_SCENE_VALIDATE_INPUT_VALUE',
    (action) => () => action({})
);

export const pinballSceneAddedBidToGame = createAction(
    'PINBALL_SCENE_ADDED_BIT_TO_GAME',
    (action) => () => action({})
);

export const pinballSceneFailedToAddBidToGame = createAction(
    'PINBALL_SCENE_FAILED_ADD_BID_TO_GAME',
    (action) => () => action({})
);

export const pinballSceneClearCurrentBidValue = createAction(
    'PINBALL_SCENE_CLEAR_CURRENT_BID_VALUE',
    (action) => () => action({})
);

export type PinballSceneActionTypes = ActionType<typeof pinballSceneDidActivateScene> |
    ActionType<typeof pinballSceneDidDeactivateScene> |
    ActionType<typeof pinballSceneMountedWithPinballSlug> |
    ActionType<typeof pinballSceneStartListeningForGameChanges> |
    ActionType<typeof pinballSceneStopListeningForGameChanges> |
    ActionType<typeof pinballSceneReceivedPinballGame> |
    ActionType<typeof pinballSceneUpdatePinballGameData> |
    ActionType<typeof pinballSceneAddBidToGame> |
    ActionType<typeof pinballSceneValidateInputValue> |
    ActionType<typeof pinballSceneUpdateManualBidValue> |
    ActionType<typeof pinballSceneClearCurrentBidValue>;
