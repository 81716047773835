import {createAction} from 'typesafe-actions';
import {act} from 'react-dom/test-utils';
import {UserDTO} from '../../../generated/swagger-api';

export const swaggerApiServiceUserControllerProfileData = createAction(
    'SWAGGER_API_SERVICE_USER_CONTROLLER_PROFILE_DATA',
    (action) => (userProfile: UserDTO) => action({userProfile})
);

export const swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnauthorizedException = createAction(
    'SWAGGER_API_SERVICE_USER_CONTROLLER_LOADING_PROFILE_DATA_FAILED_WITH_UNAUTHORIZED_EXCEPTION',
    (action) => () => action({})
);

export const swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException = createAction(
    'SWAGGER_API_SERVICE_USER_CONTROLLER_LOADING_PROFILE_DATA_FAILED_WITH_UNEXPECTED_EXCEPTION',
    (action) => () => action({})
);
