import React, {useContext} from 'react';
import {AuthenticationContext} from '../../contexts/authentication';
import {Redirect, Route, RouteComponentProps} from 'react-router';
import {Props} from './Props';

const PrivateRoute: React.FunctionComponent<Props> = ({path, exact, component: RenderComponent}) => {
    const authentication = useContext(AuthenticationContext);

    return <React.Fragment>
        <Route
            path={path}
            exact={exact}
            render={(renderProps: RouteComponentProps<any>) => {
                if (!authentication.authenticated) {
                    return <Redirect to={{
                        pathname: '/loading',
                        search: `redirect=${renderProps.location.pathname}${renderProps.location.hash}`
                    }}/>;
                }

                return <RenderComponent {...renderProps}/>;
            }}
        />
    </React.Fragment>;
};

export default PrivateRoute;
