import {DispatchProps, Props, StateProps} from './Props';
import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RootState} from '../../reducer';
import {AuthenticationContextProvider, AuthenticationContextState} from '../../contexts/authentication';
import {Redirect, Route, Switch} from 'react-router-dom';
import {
    ABOUT_THE_SALE,
    BASE,
    ERROR_SCENE,
    HOW_TO_SALE,
    MY_BIDS,
    NEW_SESSION,
    PINBALL_SINGLE,
    TOUR,
    WELCOME
} from '../../routes';
import Welcome from '../../scenes/Welcome';
import MainLayout from '../Layout/MainLayout';
import PinballScene from '../../scenes/Pinball';
import {ConnectedRouter} from 'connected-react-router';
import {
    AppComponentActionTypes,
    appComponentRequestedCloseUserSession, appComponentRequestedNavigationToAboutSalePage,
    appComponentRequestedNavigationToAdminPage,
    appComponentRequestedNavigationToHomePage, appComponentRequestedNavigationToHowToSalePage,
    appComponentRequestedNavigationToMyBidsPage
} from './actions';
import PrivateRoute from '../PrivateRoute';
import UnauthenticatedLayout from '../Layout/UnauthenticatedLayout';
import NewSessionScene from '../../scenes/NewSession';
import ErrorScene from '../../scenes/Error';
import MyBidsScene from '../../scenes/MyBids';
import TourScene from '../../scenes/Tour';
import AboutTheSale from '../../scenes/AboutTheSale';
import HowToSale from '../../scenes/HowToSale';

const App: React.FunctionComponent<Props> = (
    {
        authenticated,
        currentScene,
        browserHistory,
        onRequestedNavigationToHomePage,
        onRequestedNavigationToAdminPage,
        onRequestedNavigationToMyBidsPage,
        onRequestedNavigationToAboutSalePage,
        onRequestedNavigationToHowToSalePage,
        onCloseUserSession,
        userProfile
    }) => {
    const authenticationContextProviderValue: AuthenticationContextState = {
        authenticated,
        userProfile
    };
    const MainLayoutRoutes = [WELCOME, PINBALL_SINGLE, MY_BIDS, ABOUT_THE_SALE, HOW_TO_SALE];
    const SessionRoutes = [NEW_SESSION, ERROR_SCENE, TOUR];

    return <React.Fragment>
        <AuthenticationContextProvider value={authenticationContextProviderValue}>
            <ConnectedRouter history={browserHistory}>
                <Switch>
                    <Redirect to={WELCOME} exact={true} from={BASE}/>

                    <Route path={MainLayoutRoutes} exact>
                        <MainLayout
                            currentScene={currentScene}
                            clickedOnHomeLink={() => onRequestedNavigationToHomePage()}
                            clickedOnAdminLink={() => onRequestedNavigationToAdminPage()}
                            clickedOnMyBidsLink={() => onRequestedNavigationToMyBidsPage()}
                            clickedOnSignOutLink={() => onCloseUserSession()}
                            clickedOnAboutTheSale={() => onRequestedNavigationToAboutSalePage()}
                            clickedOnHowToSale={() => onRequestedNavigationToHowToSalePage()}
                        >
                            <Switch>
                                <PrivateRoute
                                    exact={true}
                                    path={MY_BIDS}
                                    component={MyBidsScene}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={WELCOME}
                                    component={Welcome}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={PINBALL_SINGLE}
                                    component={PinballScene}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={ABOUT_THE_SALE}
                                    component={AboutTheSale}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={HOW_TO_SALE}
                                    component={HowToSale}
                                />
                            </Switch>
                        </MainLayout>
                    </Route>
                    <Route path={SessionRoutes} exact>
                        <UnauthenticatedLayout>
                            <Switch>
                                <PrivateRoute
                                    exact={true}
                                    path={TOUR}
                                    component={TourScene}
                                />
                                <Route
                                    exact={true}
                                    path={NEW_SESSION}
                                    component={NewSessionScene}
                                />
                                <Route
                                    exact={true}
                                    path={ERROR_SCENE}
                                    component={ErrorScene}
                                />
                            </Switch>
                        </UnauthenticatedLayout>
                    </Route>
                </Switch>
            </ConnectedRouter>
        </AuthenticationContextProvider>
    </React.Fragment>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    applicationBooted: state.applicationModule.applicationBooted,
    authenticated: state.authenticationModule.authenticated,
    authenticationAvailable: state.authenticationModule.authenticationAvailable,
    currentScene: state.applicationModule.currentScene,
    userProfile: state.authenticationModule.userProfile
});

const mapDispatchToProps = (dispatch: Dispatch<AppComponentActionTypes>): DispatchProps => ({
    onCloseUserSession: () => dispatch(appComponentRequestedCloseUserSession()),
    onRequestedNavigationToHomePage: () => dispatch(appComponentRequestedNavigationToHomePage()),
    onRequestedNavigationToAdminPage: () => dispatch(appComponentRequestedNavigationToAdminPage()),
    onRequestedNavigationToMyBidsPage: () => dispatch(appComponentRequestedNavigationToMyBidsPage()),
    onRequestedNavigationToAboutSalePage: () => dispatch(appComponentRequestedNavigationToAboutSalePage()),
    onRequestedNavigationToHowToSalePage: () => dispatch(appComponentRequestedNavigationToHowToSalePage())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
