import React, {useEffect} from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import pinballFlipper from '../../assets/images/pinball-flipper.png';
import {DispatchProps, Props} from './Props';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {NewSessionSceneActionTypes, newSessionSceneStartNewSessionWithId} from './actions';

const NewSessionScene: React.FunctionComponent<Props> = ({match: {params: {id}}, onStartNewSessionWithId}) => {
    useEffect(() => {
        onStartNewSessionWithId(id);
    }, [id, onStartNewSessionWithId]);

    return <>
        <div
            className={css(styles.pageHeader)}
            style={{backgroundImage: `url('${pinballFlipper}')`}}
        >
            <p>Bezig met inloggen...</p>
            <h2>Welkom (terug)!</h2>
        </div>

        <div className={css(styles.pageContent)}>
            <p>We hebben je inloggegevens goed ontvangen en zijn je op dit moment aan het inloggen, nog even geduld...</p>
        </div>
    </>;
};

const mapDispatchToProps = (dispatch: Dispatch<NewSessionSceneActionTypes>): DispatchProps => ({
    onStartNewSessionWithId: (id) => dispatch(newSessionSceneStartNewSessionWithId(id))
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewSessionScene);
