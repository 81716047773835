import React from 'react';
import {Props} from './Props';
import backgroundImage from '../../../assets/images/unauthenticated-scene-background.jpg';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Container} from 'react-bootstrap';

const UnauthenticatedLayout: React.FunctionComponent<Props> = ({children}) => {
    return <>
        <div
            style={{backgroundImage: `url("${backgroundImage}")`}}
            className={css(styles.pageContainer)}
        >
            <Container fluid={'md'}>
                <div className={css(styles.pageContent)}>
                    {children}
                </div>
            </Container>
        </div>
    </>;
};

export default UnauthenticatedLayout;
