import React from 'react';
import {Props} from './Props';
import Currency from 'react-currency-formatter';

const CurrencyEuro: React.FunctionComponent<Props> = ({amount}) => {
    return <React.Fragment>
        <Currency quantity={amount} locale={'nl_NL'} currency={'EUR'}/>
    </React.Fragment>;
};

export default CurrencyEuro;
