import {Reducer} from 'react';
import {
    ApplicationModuleActionTypes,
    applicationModuleApplicationBootCompleted, applicationModuleFinishedLoadingFirebase,
    applicationModuleUpdateCurrentScene
} from './actions';
import {getType} from 'typesafe-actions';
import {Scenes} from '../../scenes';

export interface ApplicationModuleReducerState {
    applicationBooted: boolean;
    currentScene: Scenes;
    firebaseBooted: boolean;
}

const initialState: ApplicationModuleReducerState = {
    applicationBooted: false,
    currentScene: Scenes.LOADING,
    firebaseBooted: false
};

const applicationModuleReducer: Reducer<ApplicationModuleReducerState, ApplicationModuleActionTypes> =
    (state: ApplicationModuleReducerState = initialState, action: ApplicationModuleActionTypes): ApplicationModuleReducerState => {
        switch (action.type) {
            case getType(applicationModuleApplicationBootCompleted):
                return {
                    ...state,
                    applicationBooted: true
                };
            case getType(applicationModuleFinishedLoadingFirebase):
                return {
                    ...state,
                    firebaseBooted: true
                };
            case getType(applicationModuleUpdateCurrentScene):
                return {
                    ...state,
                    currentScene: action.payload.scene
                };
            default:
                return {
                    ...state
                };
        }
    };

export default applicationModuleReducer;
