import {combineEpics, Epic} from 'redux-observable';
import {DependencyEpic} from '../../store';
import {catchError, delay, filter, flatMap, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {createAction, isActionOf} from 'typesafe-actions';
import {
    applicationModuleApplicationBootCompleted,
    applicationModuleApplicationBootFirebase,
    applicationModuleApplicationColdBoot,
    applicationModuleBootWithSessionUrl,
    applicationModuleErrorLoadingFirebase,
    applicationModuleFinishedLoadingFirebase
} from '../../modules/application/actions';
import {LOCATION_CHANGE, push, RouterActionType} from 'connected-react-router';
import {TOUR, WELCOME} from '../../routes';
import {firebaseService, socketService} from '../../services';
import {notificationServiceNotificationPermissionGranted} from '../../services/NotificationService/actions/checkPermission';
import {concat, of} from 'rxjs';

const bootApplicationWithSessionUrlOnColdBootStartedApplication: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationColdBoot)),
        filter(() => state$.value.router.location.pathname.indexOf('/newsession') > -1),
        map(() => state$.value.router.location.pathname.replace('/newsession/', '')),
        map((sessionId) => applicationModuleBootWithSessionUrl(sessionId))
    );

const loadSessionFromStorageOnColdBootStartedApplication: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationColdBoot)),
        filter(() => state$.value.router.location.pathname.indexOf('/newsession') === -1),
        tap(() => {
            return window.location.href = process.env.REACT_APP_CONFIG_SSO_REDIRECT_URL as string;
        }),
        ignoreElements()
    );

const navigateToWelcomeSceneOnApplicationBootCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        delay(500),
        map(() => {
            let route = TOUR;

            if (localStorage.getItem('tour-completed')) {
                const lastRoute = localStorage.getItem('last-route');
                if (lastRoute) {
                    route = lastRoute;
                } else {
                    route = WELCOME;
                }
            }

            return push(route);
        })
    );

const connectToWebsocketOnApplicationBootCompleted: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        tap(() => socketService.init(state$.value.authenticationModule.sessionId!)),
        ignoreElements()
    );

const onLocationChange = createAction(
    LOCATION_CHANGE,
    (action) => (location: Location, routerAction: RouterActionType, isFirstRendering?: boolean) => action({
        location,
        action: routerAction,
        isFirstRendering
    })
);

const storeCurrentRouteInLocalStorageOnLocationChange: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(onLocationChange)),
        tap((data) => {
            if (data.payload.location.pathname.indexOf('newsession') === -1 && data.payload.location.pathname.indexOf('loading') === -1) {
                localStorage.setItem('last-route', data.payload.location.pathname);
            }
        }),
        ignoreElements()
    );

const startLoadingFirebaseOnApplicationBooted: DependencyEpic = (action$, state$, {firebase}) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootFirebase)),
        flatMap(() => firebaseService.setupFirebase()),
        tap((app) => firebase.next(app)),
        switchMap(() => concat(
            of(applicationModuleApplicationBootCompleted()),
            of(applicationModuleFinishedLoadingFirebase())
        )),
        catchError((err) => of(applicationModuleErrorLoadingFirebase(err)))
    );

const getFirebaseMessagingTokenOnNotificationPermissionGranted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(notificationServiceNotificationPermissionGranted)),
        mergeMap(() => firebaseService.getFirebaseMessagingToken())
    );

const applicationEpics: Epic = combineEpics(
    bootApplicationWithSessionUrlOnColdBootStartedApplication,
    navigateToWelcomeSceneOnApplicationBootCompleted,
    loadSessionFromStorageOnColdBootStartedApplication,
    connectToWebsocketOnApplicationBootCompleted,
    storeCurrentRouteInLocalStorageOnLocationChange,
    startLoadingFirebaseOnApplicationBooted,
    getFirebaseMessagingTokenOnNotificationPermissionGranted
);

export default applicationEpics;
