import {createAction} from 'typesafe-actions';
import {PinballDTO} from '../../../generated/swagger-api';

export const swaggerApiServicePinballControllerSingleReceivedData = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_SINGLE_RECEIVED_DATA',
    (action) => (game: PinballDTO) => action({game})
);

export const swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnauthorizedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_SINGLE_FAILED_RECEIVING_DATA_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnexpectedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_SINGLE_FAILED_RECEIVING_DATA_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
