// tslint:disable
/**
 * NFV Kasten veiling
 * Swagger API voor NFV kasten veiling
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BadRequestDTO
 */
export interface BadRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof BadRequestDTO
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof BadRequestDTO
     */
    details: string;
    /**
     * 
     * @type {Array<ViolationDTO>}
     * @memberof BadRequestDTO
     */
    violations: Array<ViolationDTO>;
}
/**
 * 
 * @export
 * @interface BiddingStateDTO
 */
export interface BiddingStateDTO {
    /**
     * 
     * @type {string}
     * @memberof BiddingStateDTO
     */
    state: BiddingStateDTOStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BiddingStateDTOStateEnum {
    NotStarted = 'bidding-not-started',
    Started = 'bidding-started',
    Finished = 'bidding-finished'
}

/**
 * 
 * @export
 * @interface InternalServerErrorDTO
 */
export interface InternalServerErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof InternalServerErrorDTO
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NotFoundDTO
 */
export interface NotFoundDTO {
    /**
     * 
     * @type {string}
     * @memberof NotFoundDTO
     */
    message: string;
}
/**
 * 
 * @export
 * @interface PinballAttributeDTO
 */
export interface PinballAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballAttributeDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PinballAttributeDTO
     */
    value: string;
}
/**
 * 
 * @export
 * @interface PinballBidDTO
 */
export interface PinballBidDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballBidDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PinballBidDTO
     */
    bidderName: string;
    /**
     * 
     * @type {number}
     * @memberof PinballBidDTO
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface PinballDTO
 */
export interface PinballDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof PinballDTO
     */
    startingPrice: number;
    /**
     * 
     * @type {number}
     * @memberof PinballDTO
     */
    currentHighestBid: number | null;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    state: PinballDTOStateEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PinballDTO
     */
    bidValues: Array<number>;
    /**
     * 
     * @type {Array<PinballAttributeDTO>}
     * @memberof PinballDTO
     */
    attributes: Array<PinballAttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    featuredImageUrl: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PinballDTO
     */
    urlsImages: Array<string>;
    /**
     * 
     * @type {Array<PinballBidDTO>}
     * @memberof PinballDTO
     */
    bids: Array<PinballBidDTO>;
}

/**
    * @export
    * @enum {string}
    */
export enum PinballDTOStateEnum {
    NotStarted = 'bidding-not-started',
    Started = 'bidding-started',
    Finished = 'bidding-finished'
}

/**
 * 
 * @export
 * @interface PinballPlaceBidDTO
 */
export interface PinballPlaceBidDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballPlaceBidDTO
     */
    pinballId: string;
    /**
     * 
     * @type {number}
     * @memberof PinballPlaceBidDTO
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface SaveUserTokenDTO
 */
export interface SaveUserTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof SaveUserTokenDTO
     */
    token: string;
}
/**
 * 
 * @export
 * @interface UnauthorizedDTO
 */
export interface UnauthorizedDTO {
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedDTO
     */
    message: string;
}
/**
 * 
 * @export
 * @interface UserBidDTO
 */
export interface UserBidDTO {
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    pinballId: string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    pinballSlug: string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    pinballName: string;
    /**
     * 
     * @type {number}
     * @memberof UserBidDTO
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserBidDTO
     */
    highestBid: boolean;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    isAdmin: boolean;
}
/**
 * 
 * @export
 * @interface ViolationDTO
 */
export interface ViolationDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDTO
     */
    path: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    property: string;
    /**
     * 
     * @type {object}
     * @memberof ViolationDTO
     */
    violations: object;
}

/**
 * PinballApi - axios parameter creator
 * @export
 */
export const PinballApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerImages: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling pinballControllerImages.');
            }
            const localVarPath = `/pinball/{slug}/images`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pinball`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerPlaceBid: async (pinballPlaceBidDTO: PinballPlaceBidDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinballPlaceBidDTO' is not null or undefined
            if (pinballPlaceBidDTO === null || pinballPlaceBidDTO === undefined) {
                throw new RequiredError('pinballPlaceBidDTO','Required parameter pinballPlaceBidDTO was null or undefined when calling pinballControllerPlaceBid.');
            }
            const localVarPath = `/pinball/place-bid`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof pinballPlaceBidDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pinballPlaceBidDTO !== undefined ? pinballPlaceBidDTO : {}) : (pinballPlaceBidDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BiddingStateDTO} biddingStateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSetState: async (biddingStateDTO: BiddingStateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingStateDTO' is not null or undefined
            if (biddingStateDTO === null || biddingStateDTO === undefined) {
                throw new RequiredError('biddingStateDTO','Required parameter biddingStateDTO was null or undefined when calling pinballControllerSetState.');
            }
            const localVarPath = `/pinball/bidding-state`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof biddingStateDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(biddingStateDTO !== undefined ? biddingStateDTO : {}) : (biddingStateDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSingle: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling pinballControllerSingle.');
            }
            const localVarPath = `/pinball/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballApi - functional programming interface
 * @export
 */
export const PinballApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerImages(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PinballApiAxiosParamCreator(configuration).pinballControllerImages(slug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PinballDTO>>> {
            const localVarAxiosArgs = await PinballApiAxiosParamCreator(configuration).pinballControllerList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PinballApiAxiosParamCreator(configuration).pinballControllerPlaceBid(pinballPlaceBidDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BiddingStateDTO} biddingStateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerSetState(biddingStateDTO: BiddingStateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PinballApiAxiosParamCreator(configuration).pinballControllerSetState(biddingStateDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerSingle(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinballDTO>> {
            const localVarAxiosArgs = await PinballApiAxiosParamCreator(configuration).pinballControllerSingle(slug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PinballApi - factory interface
 * @export
 */
export const PinballApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerImages(slug: string, options?: any): AxiosPromise<void> {
            return PinballApiFp(configuration).pinballControllerImages(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerList(options?: any): AxiosPromise<Array<PinballDTO>> {
            return PinballApiFp(configuration).pinballControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: any): AxiosPromise<void> {
            return PinballApiFp(configuration).pinballControllerPlaceBid(pinballPlaceBidDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BiddingStateDTO} biddingStateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSetState(biddingStateDTO: BiddingStateDTO, options?: any): AxiosPromise<void> {
            return PinballApiFp(configuration).pinballControllerSetState(biddingStateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSingle(slug: string, options?: any): AxiosPromise<PinballDTO> {
            return PinballApiFp(configuration).pinballControllerSingle(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballApi - object-oriented interface
 * @export
 * @class PinballApi
 * @extends {BaseAPI}
 */
export class PinballApi extends BaseAPI {
    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerImages(slug: string, options?: any) {
        return PinballApiFp(this.configuration).pinballControllerImages(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerList(options?: any) {
        return PinballApiFp(this.configuration).pinballControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: any) {
        return PinballApiFp(this.configuration).pinballControllerPlaceBid(pinballPlaceBidDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BiddingStateDTO} biddingStateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerSetState(biddingStateDTO: BiddingStateDTO, options?: any) {
        return PinballApiFp(this.configuration).pinballControllerSetState(biddingStateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerSingle(slug: string, options?: any) {
        return PinballApiFp(this.configuration).pinballControllerSingle(slug, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PinballBidApi - axios parameter creator
 * @export
 */
export const PinballBidApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballBidControllerMyBids: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pinball-bid/my-bids`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballBidApi - functional programming interface
 * @export
 */
export const PinballBidApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballBidControllerMyBids(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBidDTO>>> {
            const localVarAxiosArgs = await PinballBidApiAxiosParamCreator(configuration).pinballBidControllerMyBids(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PinballBidApi - factory interface
 * @export
 */
export const PinballBidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballBidControllerMyBids(options?: any): AxiosPromise<Array<UserBidDTO>> {
            return PinballBidApiFp(configuration).pinballBidControllerMyBids(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballBidApi - object-oriented interface
 * @export
 * @class PinballBidApi
 * @extends {BaseAPI}
 */
export class PinballBidApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballBidApi
     */
    public pinballBidControllerMyBids(options?: any) {
        return PinballBidApiFp(this.configuration).pinballBidControllerMyBids(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PinballImageApi - axios parameter creator
 * @export
 */
export const PinballImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImage: async (pinball: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinball' is not null or undefined
            if (pinball === null || pinball === undefined) {
                throw new RequiredError('pinball','Required parameter pinball was null or undefined when calling pinballImageControllerImage.');
            }
            const localVarPath = `/pinball-image/{pinball}/original`
                .replace(`{${"pinball"}}`, encodeURIComponent(String(pinball)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImageCompressed: async (pinball: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinball' is not null or undefined
            if (pinball === null || pinball === undefined) {
                throw new RequiredError('pinball','Required parameter pinball was null or undefined when calling pinballImageControllerImageCompressed.');
            }
            const localVarPath = `/pinball-image/{pinball}/compressed`
                .replace(`{${"pinball"}}`, encodeURIComponent(String(pinball)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballImageApi - functional programming interface
 * @export
 */
export const PinballImageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballImageControllerImage(pinball: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PinballImageApiAxiosParamCreator(configuration).pinballImageControllerImage(pinball, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballImageControllerImageCompressed(pinball: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PinballImageApiAxiosParamCreator(configuration).pinballImageControllerImageCompressed(pinball, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PinballImageApi - factory interface
 * @export
 */
export const PinballImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImage(pinball: string, options?: any): AxiosPromise<void> {
            return PinballImageApiFp(configuration).pinballImageControllerImage(pinball, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImageCompressed(pinball: string, options?: any): AxiosPromise<void> {
            return PinballImageApiFp(configuration).pinballImageControllerImageCompressed(pinball, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballImageApi - object-oriented interface
 * @export
 * @class PinballImageApi
 * @extends {BaseAPI}
 */
export class PinballImageApi extends BaseAPI {
    /**
     * 
     * @param {string} pinball 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballImageApi
     */
    public pinballImageControllerImage(pinball: string, options?: any) {
        return PinballImageApiFp(this.configuration).pinballImageControllerImage(pinball, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pinball 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballImageApi
     */
    public pinballImageControllerImageCompressed(pinball: string, options?: any) {
        return PinballImageApiFp(this.configuration).pinballImageControllerImageCompressed(pinball, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userControllerProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerProfile(options?: any): AxiosPromise<UserDTO> {
            return UserApiFp(configuration).userControllerProfile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerProfile(options?: any) {
        return UserApiFp(this.configuration).userControllerProfile(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserTokenApi - axios parameter creator
 * @export
 */
export const UserTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveUserTokenDTO} saveUserTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTokenControllerSaveToken: async (saveUserTokenDTO: SaveUserTokenDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveUserTokenDTO' is not null or undefined
            if (saveUserTokenDTO === null || saveUserTokenDTO === undefined) {
                throw new RequiredError('saveUserTokenDTO','Required parameter saveUserTokenDTO was null or undefined when calling userTokenControllerSaveToken.');
            }
            const localVarPath = `/user-token/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof saveUserTokenDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(saveUserTokenDTO !== undefined ? saveUserTokenDTO : {}) : (saveUserTokenDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserTokenApi - functional programming interface
 * @export
 */
export const UserTokenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveUserTokenDTO} saveUserTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTokenControllerSaveToken(saveUserTokenDTO: SaveUserTokenDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await UserTokenApiAxiosParamCreator(configuration).userTokenControllerSaveToken(saveUserTokenDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserTokenApi - factory interface
 * @export
 */
export const UserTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {SaveUserTokenDTO} saveUserTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTokenControllerSaveToken(saveUserTokenDTO: SaveUserTokenDTO, options?: any): AxiosPromise<UserDTO> {
            return UserTokenApiFp(configuration).userTokenControllerSaveToken(saveUserTokenDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserTokenApi - object-oriented interface
 * @export
 * @class UserTokenApi
 * @extends {BaseAPI}
 */
export class UserTokenApi extends BaseAPI {
    /**
     * 
     * @param {SaveUserTokenDTO} saveUserTokenDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserTokenApi
     */
    public userTokenControllerSaveToken(saveUserTokenDTO: SaveUserTokenDTO, options?: any) {
        return UserTokenApiFp(this.configuration).userTokenControllerSaveToken(saveUserTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

}


