import {forkJoin, from, of, ReplaySubject} from 'rxjs';
import * as firebase from 'firebase/app';
import {catchError, map} from 'rxjs/operators';
import 'firebase/messaging';
import {firebaseServiceMessagingToken, firebaseServiceMessagingTokenFailed} from './actions';

class FirebaseService {

    private readonly replaySubject: ReplaySubject<firebase.app.App>;

    private messaging: firebase.messaging.Messaging | null;

    constructor() {
        this.replaySubject = new ReplaySubject(1);
        this.replaySubject.asObservable();
        this.messaging = null;
    }

    setupFirebase() {
        return forkJoin(of(firebase))
            .pipe(
                map((payload) => payload[0].initializeApp({
                    apiKey: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_API_KEY,
                    authDomain: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_AUTH_DOMAIN,
                    projectId: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_PROJECT_ID,
                    storageBucket: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_STORAGE_BUCKET,
                    messagingSenderId: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_MESSAGING_SENDER_ID,
                    appId: process.env.REACT_APP_FIREBASE_SERVICE_CONFIG_APP_ID
                }))
            );
    }

    getReplaySubject(): ReplaySubject<firebase.app.App> {
        return this.replaySubject;
    }

    private setupFirebaseMessaging() {
        this.messaging = firebase.messaging();
        this.messaging.usePublicVapidKey('BGOcnn8X71RyvEsb-A4VseeHvkDyv9334s4W4yaZaYXAvnJR7k_oRJSmx7D4Cd2vBn8tejnt934ALNglspk8nCI');
    }

    getFirebaseMessagingToken() {
        if (!this.messaging) {
            this.setupFirebaseMessaging();
        }

        const promise = this.messaging!.getToken();

        return from(promise)
            .pipe(
                map((token) => firebaseServiceMessagingToken(token)),
                catchError(() => of(firebaseServiceMessagingTokenFailed()))
            );
    }
}

export default FirebaseService;
