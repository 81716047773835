import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    footerContainer: {
        color: '#363b46',
        fontSize: '75%',
        marginBottom: 20
    },
    pageContainer: {
        height: '100%',
        backgroundSize: '350px',
        backgroundPositionX: '98%',
        backgroundPositionY: '90%',
        backgroundRepeat: 'no-repeat'
    }
});

export default styles;
