import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from '../AboutTheSale/styles';

const HowToSale: React.FunctionComponent = () => {
    return <div className={css(styles.pageContainer)}>
        <div className={css(styles.mainContainer, styles.imagePagePart)}>
            <h2>Hoe werkt de kastenveiling?</h2>

            <p>Op deze pagina willen we graag een korte tekstuele uitleg geven over hoe de veiling website werkt,
                deze website bestaat uit een aantal onderdelen: Het kasten overzicht, de kast detail pagina en jouw
                biedingen overzicht.</p>

            <p>
                <strong>Kasten overzicht</strong><br/>
                Wanneer je deze rondleiding hebt afgerond kom je automatisch in het kasten overzicht terecht, hierin
                staan alle kasten die in de veiling worden aangeboden met een enkele foto. Je kan het huidige
                hoogste bod zien, of de minimale prijs van de kast wanneer nog geen biedingen zijn geplaatst. Ook
                kan je met de knop <i>'Bekijk deze kast'</i> de kast details bekijken en eventueel een bod
                plaatsen.
            </p>

            <p>
                <strong>Kast detail pagina</strong><br/>
                Op deze pagina vind je alle informatie over de veiling kast, zoals de laatste 10 biedingen, wat
                extra informatie zoals merk en type (EM/SS), en een omschrijving van de staat van de kast. Ook is er
                op deze pagina een foto weergave ingebouwd waarin je alle foto's van de kast rustig kunt bekijken.
                Dit kan zowel in volledig scherm als de normale weergave, ook zit links onder een pauze knop.<br/>
                Rechts onder de lijst met biedingen kan je een bod uitbrengen, dit kan met het invoerveld waar je
                handmatig je bod in kan vullen of met een van de knoppen die onder het invoer veld staan. De waarde
                van de aangeklikte knop wordt dan bij het hoogste bod (of wanneer geen bod geplaatst de minimum
                prijs) toegevoegd.
            </p>

            <p>
                <strong>Mijn biedingen</strong><br/>
                Op deze pagina kan je al je biedingen inzien die je gedaan hebt, wanneer het bedrag groen gekleurd
                is heb jij nog steeds het hoogste bod staan, wanneer het bedrag rood is betekent het dat je
                overboden bent. Met de meest rechter knop kan je ook gemakkelijk terug gaan naar de flipperkast
                detail pagina in kwestie.
            </p>
        </div>
    </div>;
};


export default HowToSale;
