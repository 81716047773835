import {createAction} from 'typesafe-actions';

export const notificationServiceNotificationPermissionGranted = createAction(
    'NOTIFICATION_SERVICE_NOTIFICATION_PERMISSION_GRANTED',
    (action) => () => action({})
);

export const notificationServiceNotificationPermissionDenied = createAction(
    'NOTIFICATION_SERVICE_NOTIFICATION_PERMISSION_DENIED',
    (action) => () => action({})
);

export const notificationServiceNotificationPermissionDefault = createAction(
    'NOTIFICATION_SERVICE_NOTIFICATION_PERMISSION_DEFAULT',
    (action) => () => action({})
);

export const notificationServiceNotificationPermissionFailed = createAction(
    'NOTIFICATION_SERVICE_NOTIFICATION_PERMISSION_FAILED',
    (action) => () => action({})
);
