import {ActionType, createAction} from 'typesafe-actions';
import {UserDTO} from '../../generated/swagger-api';

export const authenticationModuleUserAuthenticated = createAction(
    'AUTHENTICATION_MODULE_USER_AUTHENTICATED',
    (action) => (sessionId: string) => action({sessionId})
);

export const authenticationModuleStartUserLogout = createAction(
    'AUTHENTICATION_MODULE_START_USER_LOGOUT',
    (action) => () => action({})
);

export const authenticationModuleFinishedUserLogout = createAction(
    'AUTHENTICATION_MODULE_FINISHED_USER_LOGOUT',
    (action) => () => action({})
);

export const authenticationModuleLoadedUserProfileData = createAction(
    'APPLICATION_MODULE_LOADED_USER_PROFILE_DATA',
    (action) => (user: UserDTO) => action({user})
);

export const authenticationModuleLoadingUserProfileFailed = createAction(
    'APPLICATION_MODULE_LOADING_USER_PROFILE_FAILED',
    (action) => () => action({})
);

export type AuthenticationModuleActionsType = ActionType<typeof authenticationModuleUserAuthenticated> |
    ActionType<typeof authenticationModuleStartUserLogout> |
    ActionType<typeof authenticationModuleLoadedUserProfileData> |
    ActionType<typeof authenticationModuleLoadingUserProfileFailed> |
    ActionType<typeof authenticationModuleFinishedUserLogout>;
