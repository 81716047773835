import {Reducer} from 'redux';
import {
    TourSceneActionTypes, tourSceneDidDeactivateScene, tourSceneFinishedSettingUpNotifications,
    tourSceneRequestNotificationPermission,
    tourSceneSetStep,
    tourSceneUpdateNotificationPermissionState
} from './actions';
import {getType} from 'typesafe-actions';

export enum TourSceneStep {
    WELCOME = 'welcome',
    NOTIFICATIONS = 'notifications',
    BIDDING = 'bidding',
    HOW_TO = 'how-to'
}

export enum TourSceneNotificationState {
    DENIED = 'denied',
    GRANTED = 'granted',
    FAILED = 'failed'
}

export interface TourSceneReducerState {
    currentStep: TourSceneStep;
    notificationPermissionStateLoading: boolean;
    notificationPermissionState: TourSceneNotificationState | null;
    notificationSetupDone: boolean;
}

const initialState: TourSceneReducerState = {
    currentStep: TourSceneStep.WELCOME,
    notificationPermissionStateLoading: false,
    notificationPermissionState: null,
    notificationSetupDone: false
};

const tourSceneReducer: Reducer<TourSceneReducerState, TourSceneActionTypes> =
    (state: TourSceneReducerState = initialState, action: TourSceneActionTypes) => {
        switch (action.type) {
            case getType(tourSceneDidDeactivateScene):
                return {
                    currentStep: TourSceneStep.WELCOME,
                    notificationPermissionStateLoading: false,
                    notificationPermissionState: null,
                    notificationSetupDone: false
                };
            case getType(tourSceneSetStep):
                return {
                    ...state,
                    currentStep: action.payload.nextStep
                };
            case getType(tourSceneRequestNotificationPermission):
                return {
                    ...state,
                    notificationPermissionStateLoading: true
                };
            case getType(tourSceneUpdateNotificationPermissionState):
                return {
                    ...state,
                    notificationPermissionState: action.payload.notificationState
                };
            case getType(tourSceneFinishedSettingUpNotifications):
                return {
                    ...state,
                    notificationPermissionStateLoading: false,
                    notificationSetupDone: true
                };
            default:
                return {
                    ...state
                };
        }
    };

export default tourSceneReducer;
