import React, {useEffect} from 'react';
import {DispatchProps, Props, StateProps} from './Props';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {
    TourSceneActionTypes,
    tourSceneDidActivateScene,
    tourSceneDidDeactivateScene,
    tourSceneFinishTour,
    tourSceneGoToNextStep,
    tourSceneRequestNotificationPermission
} from './actions';
import {css} from 'aphrodite/no-important';
import pinballFlipper from '../../assets/images/pinball-flipper.png';
import {Button, Col, Row} from 'react-bootstrap';
import {faBell, faCheckCircle, faChevronRight, faHandPointLeft, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RootState} from '../../reducer';
import {TourSceneStep} from './reducer';
import styles from './styles';

export const TourScene: React.FunctionComponent<Props> = (
    {
        onActivatedScene,
        onDeactivatedScene,
        onGoToNextStep,
        onRequestNotificationPermission,
        tourStep,
        onFinishTour,
        notificationPermissionStateLoading,
        notificationSetupDone
    }) => {
    useEffect(
        () => {
            onActivatedScene();

            return function cleanup() {
                onDeactivatedScene();
            };
        },
        [onActivatedScene, onDeactivatedScene]
    );

    const iconColor = (): string => {
        if (notificationSetupDone) {
            return 'text-success';
        } else if (notificationPermissionStateLoading) {
            return 'text-info';
        } else {
            return 'text-warning';
        }
    };

    return <>
        <div
            className={css(styles.pageHeader)}
            style={{backgroundImage: `url('${pinballFlipper}')`}}
        >
            {tourStep === TourSceneStep.WELCOME && <><p>Welkom :)</p>
                <h2>Welkom bij de 2020 NFV Kasten Veiling</h2></>}
            {tourStep === TourSceneStep.NOTIFICATIONS && <><p>Makkelijke updates</p>
                <h2>Push notificaties instellen</h2></>}
            {tourStep === TourSceneStep.BIDDING && <><p>Algemene "spelregels" voor de veiling</p>
                <h2>Belangrijke informatie over de veiling</h2></>}
            {tourStep === TourSceneStep.HOW_TO && <><p>Maar hoe dan? :)</p>
                <h2>Uitleg van de veiling website</h2></>}
        </div>

        <div className={css(styles.pageContent)}>
            {tourStep === TourSceneStep.WELCOME && <><p>Welkom op de website van de NFV Kasten Veiling, via deze korte
                rondleiding willen je graag kort uitleggen
                hoe de veiling in zijn werking zal gaan. Deze informatie kan je uiteraard ook later terug lezen nadat
                deze rondleiding is afgelopen.</p>

                <p>Graag verzoeken we je deze informatie goed door te lezen voordat je een bod plaatst, of je t.z.t. je
                    kast komt ophalen.</p>

                <div className={'text-right'}>
                    <Button onClick={() => onGoToNextStep()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Naar stap twee
                    </Button>
                </div>
            </>}

            {tourStep === TourSceneStep.NOTIFICATIONS && <><p>Graag willen we je tijdens de veiling updates kunnen
                sturen, dit kan reiken van overbiedingen op je flipperkasten tot berichten van het veiling team. Veel
                moderne browsers (Firefox en Chrome op de desktop, en Chrome op Android telefoons en tabblets) hebben
                hiervoor standaard mogelijkheden ingebouwd zitten.</p>

                <hr/>

                <Row>
                    <Col xs={12} sm={8}>
                        <p>Om push notificaties te kunnen sturen moet je hiervoor eerst toestemming geven, dit is alles
                            wat je moet doen om te beginnen met het ontvangen van notificaties. Klik op de knop
                            hieronder om toestemming te vragen.</p>

                        <Button
                            onClick={() => onRequestNotificationPermission()}
                            disabled={notificationSetupDone}
                        >
                            Toestemming vragen
                        </Button>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className={' text-center ' + iconColor()}>
                            <span className={css(styles.notificationStatusContainer)}>
                                <FontAwesomeIcon icon={faBell} size={'6x'}/>
                                <div className={css(styles.notificationStatusFlag)}>
                                    {notificationSetupDone && <FontAwesomeIcon icon={faCheckCircle} size={'2x'}/>}
                                    {notificationPermissionStateLoading &&
                                    <FontAwesomeIcon spin={true} icon={faSpinner} size={'2x'}/>}
                                    {!notificationPermissionStateLoading && !notificationSetupDone &&
                                    <FontAwesomeIcon icon={faHandPointLeft} size={'2x'}/>}
                                </div>
                            </span>
                        </div>
                    </Col>
                </Row>

                <div className={'text-right'}>
                    {!notificationSetupDone && <Button onClick={() => onGoToNextStep()} variant={'outline-danger'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Verder zonder notificaties
                    </Button>}
                    {notificationSetupDone && <Button onClick={() => onGoToNextStep()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Naar de volgende stap
                    </Button>}
                </div>
            </>}

            {tourStep === TourSceneStep.BIDDING && <>
                <p>Het bieden op de veiling staat alleen open voor NFV-leden. We hebben gekozen voor een minimum prijs
                    bij de veiling die is afgeleid van de verzekerde waarde. Op die manier kunnen leden voor een leuke
                    prijs mogelijk een kast krijgen en is het niet zo dat we de kasten ‘weggeven’ en de andere leden het
                    idee zouden kunnen krijgen dat de vereniging benadeeld wordt.</p>

                <p>De leden kunnen biedingen doen via de veiling website. Start biedingen: 1 augustus, einddatum
                    veiling: 27 augustus 18.00u. De winnaars van de veiling krijgen persoonlijk bericht en de kasten
                    kunnen dan op een klusdag opgehaald worden. De eerste gelegenheid daarvoor is zaterdag 29
                    augustus.</p>

                <p>De spelregels voor de veiling zijn:</p>
                <ol>
                    <li>Bieding geschiedt uitsluitend via de veiling website. Je voor en achternaam zullen op de veiling
                        website bij het
                        bod geplaatst worden, je gaat hiermee akkoord als je gebruik maakt van de veiling website. Ter
                        info: deze website is alleen zichtbaar voor ingelogde NFV-leden.
                    </li>
                    <li>Na sluiting van de veiling worden de resultaten op de website geplaatst.</li>
                    <li>We hebben de kasten stuk voor stuk bekeken en naar beste kunnen een omschrijving gemaakt per
                        kast en gedetailleerde foto's gemaakt. Desondanks zullen er zeker gebreken zijn die niet
                        opgemerkt zijn door ons. Opmerkingen zoals in optisch goede staat houden rekening met de
                        leeftijd van de kast, betekent dus niet nieuwstaat. Houdt hier dus rekening mee met je bod,
                        bekijk de foto's dus goed, prijs is 'as is', zonder garantie! Biedingen zijn tevens niet
                        vrijblijvend maar verplichten je tot aankoop.
                    </li>
                    <li>Indien je een winnend bod hebt uitgebracht verkrijg je betaalinstructie. Betaling dient te
                        geschieden binnen 1 week en voor het ophalen. Eerste ophaalmogelijkheid is momenteel tijdens de
                        klusdag 29 augustus.
                    </li>
                </ol>

                <p>Van sommige kasten ontbreekt de kopkastsleutel. Van deze kasten zijn dus geen foto's gemaakt van de
                    binnenkant van de kopkast.</p>

                <div className={'text-right'}>
                    <Button onClick={() => onGoToNextStep()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Naar de volgende stap
                    </Button>
                </div>
            </>}

            {tourStep === TourSceneStep.HOW_TO && <>
                <p>Op deze pagina willen we graag een korte tekstuele uitleg geven over hoe de veiling website werkt,
                    deze website bestaat uit een aantal onderdelen: Het kasten overzicht, de kast detail pagina en jouw
                    biedingen overzicht.</p>

                <p>
                    <strong>Kasten overzicht</strong><br/>
                    Wanneer je deze rondleiding hebt afgerond kom je automatisch in het kasten overzicht terecht, hierin
                    staan alle kasten die in de veiling worden aangeboden met een enkele foto. Je kan het huidige
                    hoogste bod zien, of de minimale prijs van de kast wanneer nog geen biedingen zijn geplaatst. Ook
                    kan je met de knop <i>'Bekijk deze kast'</i> de kast details bekijken en eventueel een bod
                    plaatsen.
                </p>

                <p>
                    <strong>Kast detail pagina</strong><br/>
                    Op deze pagina vind je alle informatie over de veiling kast, zoals de laatste 10 biedingen, wat
                    extra informatie zoals merk en type (EM/SS), en een omschrijving van de staat van de kast. Ook is er
                    op deze pagina een foto weergave ingebouwd waarin je alle foto's van de kast rustig kunt bekijken.
                    Dit kan zowel in volledig scherm als de normale weergave, ook zit links onder een pauze knop.<br/>
                    Rechts onder de lijst met biedingen kan je een bod uitbrengen, dit kan met het invoerveld waar je
                    handmatig je bod in kan vullen of met een van de knoppen die onder het invoer veld staan. De waarde
                    van de aangeklikte knop wordt dan bij het hoogste bod (of wanneer geen bod geplaatst de minimum
                    prijs) toegevoegd.
                </p>

                <p>
                    <strong>Mijn biedingen</strong><br/>
                    Op deze pagina kan je al je biedingen inzien die je gedaan hebt, wanneer het bedrag groen gekleurd
                    is heb jij nog steeds het hoogste bod staan, wanneer het bedrag rood is betekent het dat je
                    overboden bent. Met de meest rechter knop kan je ook gemakkelijk terug gaan naar de flipperkast
                    detail pagina in kwestie.
                </p>

                <div className={'text-right'}>
                    <Button onClick={() => onFinishTour()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Afronden en naar de veiling
                    </Button>
                </div>
            </>}
        </div>
    </>;
};

const mapDispatchToProps = (dispatch: Dispatch<TourSceneActionTypes>): DispatchProps => ({
    onActivatedScene: () => dispatch(tourSceneDidActivateScene()),
    onDeactivatedScene: () => dispatch(tourSceneDidDeactivateScene()),
    onGoToNextStep: () => dispatch(tourSceneGoToNextStep()),
    onRequestNotificationPermission: () => dispatch(tourSceneRequestNotificationPermission()),
    onFinishTour: () => dispatch(tourSceneFinishTour())
});

const mapStateToProps = (state: RootState): StateProps => ({
    tourStep: state.tourScene.currentStep,
    notificationPermissionState: state.tourScene.notificationPermissionState,
    notificationPermissionStateLoading: state.tourScene.notificationPermissionStateLoading,
    notificationSetupDone: state.tourScene.notificationSetupDone
});

export default connect(mapStateToProps, mapDispatchToProps)(TourScene);
