import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    appComponentRequestedCloseUserSession,
    appComponentRequestedNavigationToAboutSalePage,
    appComponentRequestedNavigationToHomePage,
    appComponentRequestedNavigationToHowToSalePage,
    appComponentRequestedNavigationToMyBidsPage
} from '../../components/App/actions';
import {push} from 'connected-react-router';
import {ABOUT_THE_SALE, HOW_TO_SALE, MY_BIDS, WELCOME} from '../../routes';
import {authenticationModuleStartUserLogout} from '../../modules/authentication/actions';

const pushRouteOnRequestedNavigationToHomePage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(appComponentRequestedNavigationToHomePage)),
        map(() => push(WELCOME))
    );

const pushRouteOnRequestedNavigationToMyBidsPage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(appComponentRequestedNavigationToMyBidsPage)),
        map(() => push(MY_BIDS))
    );

const pushRouteOnRequestedNavigationToHowToSalePage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(appComponentRequestedNavigationToHowToSalePage)),
        map(() => push(HOW_TO_SALE))
    );

const pushRouteOnRequestedNavigationToAboutSalePage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(appComponentRequestedNavigationToAboutSalePage)),
        map(() => push(ABOUT_THE_SALE))
    );

const startLogoutOnRequestedCloseUserSession: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(appComponentRequestedCloseUserSession)),
        map(() => authenticationModuleStartUserLogout())
    );

const appComponent: Epic = combineEpics(
    pushRouteOnRequestedNavigationToMyBidsPage,
    pushRouteOnRequestedNavigationToHomePage,
    startLogoutOnRequestedCloseUserSession,
    pushRouteOnRequestedNavigationToHowToSalePage,
    pushRouteOnRequestedNavigationToAboutSalePage
);

export default appComponent;
