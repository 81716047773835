import authenticationModuleReducer, {AuthenticationModuleReducerState} from './modules/authentication/reducer';
import {combineReducers} from 'redux';
import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';
import applicationModuleReducer, {ApplicationModuleReducerState} from './modules/application/reducer';
import pinballModuleReducer, {PinballModuleReducerState} from './modules/pinball/reducer';
import pinballSceneReducer, {PinballSceneReducerState} from './scenes/Pinball/reducer';
import myBidsSceneReducer, {MyBidsSceneReducerState} from './scenes/MyBids/reducer';
import tourSceneReducer, {TourSceneReducerState} from './scenes/Tour/reducer';

export interface RootState {
    applicationModule: ApplicationModuleReducerState;
    authenticationModule: AuthenticationModuleReducerState;
    myBids: MyBidsSceneReducerState;
    pinballModule: PinballModuleReducerState;
    pinballScene: PinballSceneReducerState;
    tourScene: TourSceneReducerState;
    router: RouterState;
}

const rootReducer = (history: History) => combineReducers({
    applicationModule: applicationModuleReducer,
    authenticationModule: authenticationModuleReducer,
    myBids: myBidsSceneReducer,
    pinballModule: pinballModuleReducer,
    pinballScene: pinballSceneReducer,
    tourScene: tourSceneReducer,
    router: connectRouter(history)
});

export default rootReducer;
