import React from 'react';
import {UserDTO} from '../generated/swagger-api';

export interface AuthenticationContextState {
    authenticated: boolean;
    userProfile: UserDTO | null;
}

export const AuthenticationContext = React.createContext<AuthenticationContextState>({
    authenticated: false,
    userProfile: null
});

export const AuthenticationContextProvider = AuthenticationContext.Provider;
