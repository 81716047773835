import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../reducer';
import {DispatchProps, Props, StateProps} from './Props';
import {Button, Card, Col, Row} from 'react-bootstrap';
import Currency from 'react-currency-formatter';
import {Dispatch} from 'redux';
import {
    WelcomeSceneActionTypes,
    welcomeSceneDidActivateScene,
    welcomeSceneDidDeactivateScene,
    welcomeSceneNavigateToPinballGame
} from './actions';
import PinballStatus from '../../components/PinballStatus';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {PinballDTO} from '../../generated/swagger-api';

const Welcome: React.FunctionComponent<Props> = (
    {
        pinballGames,
        onNavigateToPinballGame,
        onActivatedScene,
        onDeactivatedScene
    }) => {

    const navigateToPinballGame = (game: PinballDTO) => {
        onNavigateToPinballGame(game.slug);
    };

    useEffect(
        () => {
            onActivatedScene();

            return function cleanup() {
                onDeactivatedScene();
            };
        },
        [onActivatedScene, onDeactivatedScene]
    );

    return <React.Fragment>

        <Row className={css(styles.gamesContainer)}>
            <Col xs={12}>
                <Row>
                    {pinballGames.map((game, index: number) => <Col xs={12} md={4} key={index}>
                        <Card>
                            {game.featuredImageUrl && <>
                                <div className={css(styles.imageContainer) + ' d-none d-md-block'}>
                                    <img
                                        src={`${process.env.REACT_APP_CONFIG_API_BASE_URL}${game.featuredImageUrl}`}
                                        alt={game.name}
                                        className={css(styles.image)}
                                    />
                                </div>
                            </>}
                            <Card.Body>
                                <Card.Title>{game.name} <PinballStatus pinballGame={game}
                                                                       smallFontSize={true}/><br/></Card.Title>
                                <Card.Text>

                                    {!game.currentHighestBid && <>Bieden vanaf:&nbsp;
                                        <Currency
                                            quantity={game.startingPrice}
                                            currency={'EUR'}
                                            locale="nl_NL"
                                        /></>}
                                    {game.currentHighestBid && <>
                                        <strong>Huidig bod:&nbsp;
                                            <Currency
                                                quantity={game.currentHighestBid}
                                                currency={'EUR'}
                                                locale="nl_NL"
                                            />
                                        </strong>
                                    </>}
                                </Card.Text>
                                <Button
                                    variant={'outline-info'}
                                    onClick={() => navigateToPinballGame(game)}
                                    block={true}
                                >
                                    Bekijk deze kast <FontAwesomeIcon icon={faChevronRight}/>
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>)}
                </Row>
            </Col>
        </Row>

    </React.Fragment>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    pinballGames: state.pinballModule.games
});
const mapDispatchToProps = (dispatch: Dispatch<WelcomeSceneActionTypes>): DispatchProps => ({
    onActivatedScene: () => dispatch(welcomeSceneDidActivateScene()),
    onDeactivatedScene: () => dispatch(welcomeSceneDidDeactivateScene()),
    onNavigateToPinballGame: (slug: string) => dispatch(welcomeSceneNavigateToPinballGame(slug))
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
