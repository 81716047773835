import {combineEpics, Epic} from 'redux-observable';
import {filter, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {firebaseServiceMessagingToken} from '../../services/FirebaseService/actions';
import {swaggerApiService} from '../../services';
import {DependencyEpic} from '../../store';

const saveUserTokenOnFirebaseServiceReceivedMessagingToken: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(firebaseServiceMessagingToken)),
        mergeMap(({payload: {token}}) => swaggerApiService.saveToken(state$.value.authenticationModule.sessionId!, token))
    );

const firebaseService: Epic = combineEpics(
    saveUserTokenOnFirebaseServiceReceivedMessagingToken
);

export default firebaseService;
