import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import pinballFlipper from '../../assets/images/pinball-flipper.png';

const ErrorScene: React.FunctionComponent = () => {
    return <>
        <div
            className={css(styles.pageHeader)}
            style={{backgroundImage: `url('${pinballFlipper}')`}}
        >
            <p>Helaas...</p>
            <h2>Er is iets misgegaan :(</h2>
        </div>

        <div className={css(styles.pageContent)}>
            <p>Tijdens het opstarten/gebruik van de NFV Kastenveiling is er helaas is er helaas iets mis gegaan, we
                zullen je automatisch omleiding naar het inlogscherm.</p>
        </div>
    </>;
};

export default ErrorScene;
