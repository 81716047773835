import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    pageHeader: {
        backgroundColor: '#3F87CF',
        backgroundSize: '150px',
        backgroundPositionX: '98%',
        backgroundPositionY: '90%',
        backgroundRepeat: 'no-repeat',
        padding: 20,
        color: 'white'
    },
    pageContent: {
        padding: 20
    }
});

export default styles;
