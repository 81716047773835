import {from, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
    notificationServiceNotificationPermissionDefault,
    notificationServiceNotificationPermissionDenied, notificationServiceNotificationPermissionFailed,
    notificationServiceNotificationPermissionGranted
} from './actions/checkPermission';

export class NotificationService {

    checkPermission() {
        const promise = Notification.requestPermission();

        return from(promise)
            .pipe(
                map((response) => {
                    if (response === 'granted') {
                        return notificationServiceNotificationPermissionGranted();
                    } else if (response === 'denied') {
                        return notificationServiceNotificationPermissionDenied();
                    } else {
                        return notificationServiceNotificationPermissionDefault();
                    }
                }),
                catchError(() => of(notificationServiceNotificationPermissionFailed()))
            );
    }
}
