import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {DependencyEpic} from '../../store';
import {
    tourSceneFinishedSettingUpNotifications,
    tourSceneFinishTour,
    tourSceneGoToNextStep,
    tourSceneRequestNotificationPermission,
    tourSceneSetStep,
    tourSceneUpdateNotificationPermissionState
} from '../../scenes/Tour/actions';
import {TourSceneNotificationState, TourSceneStep} from '../../scenes/Tour/reducer';
import {notificationService} from '../../services';
import {
    notificationServiceNotificationPermissionDefault,
    notificationServiceNotificationPermissionDenied,
    notificationServiceNotificationPermissionFailed,
    notificationServiceNotificationPermissionGranted
} from '../../services/NotificationService/actions/checkPermission';
import {push} from 'connected-react-router';
import {WELCOME} from '../../routes';
import {swaggerApiServiceUserTokenControllerSavedToken} from '../../services/SwaggerApiService/actions/userTokenSaveToken';

const setNextStepOnGoToNextStep: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(tourSceneGoToNextStep)),
        map(() => {
            let nextStep: TourSceneStep = TourSceneStep.WELCOME;

            if (state$.value.tourScene.currentStep === TourSceneStep.WELCOME && ('Notification' in window)) {
                nextStep = TourSceneStep.NOTIFICATIONS;
            } else if (state$.value.tourScene.currentStep === TourSceneStep.WELCOME && !('Notification' in window)) {
                nextStep = TourSceneStep.BIDDING;
            } else if (state$.value.tourScene.currentStep === TourSceneStep.NOTIFICATIONS) {
                nextStep = TourSceneStep.BIDDING;
            } else if (state$.value.tourScene.currentStep === TourSceneStep.BIDDING) {
                nextStep = TourSceneStep.HOW_TO;
            }

            return tourSceneSetStep(nextStep);
        })
    );

const askNotificationPermission: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(tourSceneRequestNotificationPermission)),
        mergeMap(() => notificationService.checkPermission())
    );

const updateNotificationStateOnNotificationServicePermissionGranted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(notificationServiceNotificationPermissionGranted)),
        map(() => tourSceneUpdateNotificationPermissionState(TourSceneNotificationState.GRANTED))
    );

const updateNotificationStateOnNotificationServicePermissionDenied: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(notificationServiceNotificationPermissionDenied)),
        map(() => tourSceneUpdateNotificationPermissionState(TourSceneNotificationState.DENIED))
    );

const updateNotificationStateOnNotificationServicePermissionFailed: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            notificationServiceNotificationPermissionDefault,
            notificationServiceNotificationPermissionFailed
        ])),
        map(() => tourSceneUpdateNotificationPermissionState(TourSceneNotificationState.FAILED))
    );

const setLocalStorageAndRedirectOnFinishTour: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(tourSceneFinishTour)),
        tap(() => localStorage.setItem('tour-completed', '1')),
        map(() => push(WELCOME))
    );

const finishSettingUpNotificationOnStoredNotificationToken: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServiceUserTokenControllerSavedToken)),
        map(() => tourSceneFinishedSettingUpNotifications())
    );

const tourSceneEpics: Epic = combineEpics(
    setNextStepOnGoToNextStep,
    askNotificationPermission,
    updateNotificationStateOnNotificationServicePermissionGranted,
    updateNotificationStateOnNotificationServicePermissionDenied,
    updateNotificationStateOnNotificationServicePermissionFailed,
    setLocalStorageAndRedirectOnFinishTour,
    finishSettingUpNotificationOnStoredNotificationToken
);

export default tourSceneEpics;
