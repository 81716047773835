import {ActionType, createAction} from 'typesafe-actions';

export const appComponentRequestedNavigationToHomePage = createAction(
    'APP_COMPONENT_REQUESTED_NAVIGATION_TO_HOME_PAGE',
    (action) => () => action({})
);

export const appComponentRequestedNavigationToAdminPage = createAction(
    'APP_COMPONENT_REQUESTED_NAVIGATION_TO_ADMIN_PAGE',
    (action) => () => action({})
);

export const appComponentRequestedNavigationToMyBidsPage = createAction(
    'APP_COMPONENT_REQUESTED_NAVIGATION_TO_MY_BIDS_PAGE',
    (action) => () => action({})
);

export const appComponentRequestedNavigationToAboutSalePage = createAction(
    'APP_COMPONENT_REQUESTED_NAVIGATION_TO_ABOUT_SALE_PAGE',
    (action) => () => action({})
);

export const appComponentRequestedNavigationToHowToSalePage = createAction(
    'APP_COMPONENT_REQUESTED_NAVIGATION_TO_HOW_TO_SALE_PAGE',
    (action) => () => action({})
);

export const appComponentRequestedCloseUserSession = createAction(
    'APP_COMPONENT_REQUESTED_CLOSE_USER_SESSIONS',
    (action) => () => action({})
);

export type AppComponentActionTypes = ActionType<typeof appComponentRequestedNavigationToHomePage> |
    ActionType<typeof appComponentRequestedNavigationToAdminPage> |
    ActionType<typeof appComponentRequestedNavigationToMyBidsPage> |
    ActionType<typeof appComponentRequestedNavigationToAboutSalePage> |
    ActionType<typeof appComponentRequestedNavigationToHowToSalePage> |
    ActionType<typeof appComponentRequestedCloseUserSession>;
